<template>
    <div class="t_name">
        <div class="flex items-center">
            <div class="mr-1" v-if="showChildren && record.children_count > 0">
                <a-button
                    v-if="!record.children"
                    type="link"
                    size="small"
                    :loading="loading"
                    class="p-0 text-current"
                    @click="getChildren()"
                    icon="plus-circle" />
                <a-button
                    v-else
                    type="link"
                    size="small"
                    class="p-0 text-current"
                    @click="clearChildren()"
                    icon="minus-circle" />
            </div>
            <div class="item_name blue_color" :class="record.status === 'completed' && 'completed'" @click="openTask(record)">
                {{text}}
            </div>
            <a-tag class="ml-1 mr-1 name_tag" v-if="record.attachments && record.attachments.length" @click="openTask(record)">
                <a-icon type="paper-clip" />
                {{record.attachments.length}}
            </a-tag>
            <a-tag class="ml-1 mr-0 name_tag" v-if="record.children_count">
                <span class="flex items-center">
                    <i class="fi fi-rr-folder-tree mr-1"></i>
                    {{ record.children_count }}
                </span>
            </a-tag>
            <span 
                v-if="record.priority === 3" 
                class="priority">
                <a-tooltip :title="$t('task.large_priority')">
                    <img src="../../assets/images/fire.svg" />
                </a-tooltip>
            </span>
            <span 
                v-if="record.priority === 4" 
                class="priority ">
                <a-tooltip  :title="$t('task.very_large_priority')">
                    <div class="flex">
                        <img src="../../assets/images/rocket.svg" />
                    </div>
                </a-tooltip>
            </span>
            <span 
                v-if="record.priority === 0" 
                class="priority ">
                <a-tooltip  :title="$t('task.very_low_priority')">
                    <div class="flex">
                        <img src="../../assets/images/down-arrow.svg" />
                    </div>
                </a-tooltip>
            </span>
            <!-- <span 
                v-if="record.priority === 0" 
                class="priority">
                <a-tooltip :title="$t('task.small_priority')">
                    <img src="../../assets/images/turtle-svgrepo-com.svg" />
                </a-tooltip>
            </span> -->
        </div>
    </div>
</template>

<script>
import eventBus from '@/utils/eventBus'
export default {
    props: {
        value: Array,
        extendDrawer: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            required: true
        },
        record: {
            type: Object,
            required: true
        },
        showChildren: {
            type : Boolean,
            default: true
        },
        main: {
            type: Boolean,
            default: false
        },
        reloadTask: {
            type: Function,
            default: () => null
        },
        indent: {
            type: Object,
            default: () => null
        },
        expanded: {
            type: Number,
            default: null
        },
        pageName: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            expandList: this.value,
            loading: false
        }
    },
    methods: {
        clearChildren() {
            eventBus.$emit(`table_row_${this.pageName}`, {
                action: 'collapse',
                parentId: this.record.id,
            })
            const index = this.expandList.findIndex(row => row === this.record.id)
            if(index !== -1) {
                this.expandList.splice(index, 1)
                this.$emit('input', this.expandList)
            }
            this.$store.commit('task/TASK_CLEAR_CHILD', this.record)
        },
        async getChildren(item) {
            try {
                this.loading = true
                const data = await this.$store.dispatch('task/getChildren', this.record)
                console.log(data)
                eventBus.$emit(`table_row_${this.pageName}`, {
                    action: 'expand',
                    parentId: this.record.id,
                    row: data.results
                })
                this.expandList.push(`${this.record.id}-${this.expanded}`)
                this.$emit('input', this.expandList)
            } catch(e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        },
        async openTask() {
            this.$store.commit('task/SET_PAGE_NAME', {
                pageName: this.pageName
            })

            if(this.main) {
                let query = Object.assign({}, this.$route.query)
                if(query.task && Number(query.task) !== this.record.id || !query.task) {
                    query.task = this.record.id
                    this.$router.push({query})
                    this.reloadTask(this.record)
                }
            } else {
                let query = Object.assign({}, this.$route.query)
                if(!query.task) {
                    query.task = this.record.id
                } else {
                    delete query.task
                }
                await this.$router.push({query})

                this.reloadTask(this.record)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.t_name{
    display: inline-block;
    .name_tag{
        font-size: 10px;
        padding: 0 3px;
        line-height: 16px;
    }
    .priority{
        margin-left: 5px;
        min-height: 16px;
        min-width: 16px;
        img{
            width: 16px;
            height: 16px;
        }
    }
}
.item_name{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: color 0.3s;
    word-break: break-word;
    cursor: pointer;
    &:hover{
        color: var(--primaryColor);
    }
    &.completed{
        color: var(--grayColor2);
        text-decoration: line-through;
    }
}
</style>
